import React from 'react';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { makeStyles } from '@material-ui/core/styles';

import {
  Typography, CustomButton, Dialog
} from '../../components';
import { ExpandIcon } from '../../../assets/icons';
import * as actions from '../redux/actions';

const useStyles = makeStyles(() => ({
  container: {
    width: '100%',
    maxHeight: '500px',
    overflow: 'auto',
    flexDirection: 'column',
  },
  dropdownIcon: {
    '&::after': {
      display: 'none !important'
    },
    '& svg': {
      width: '1.3em',
      height: '1.3em',
    }
  },
  dropdownItem: {
    padding: '0 !important',
  },
  header: {
    width: '100%',
    height: '110px',
    backgroundColor: process.env.REACT_APP_COLOR_PREVIEW_HEADER,
    display: 'flex',
    alignItems: 'center',
    padding: '25px'
  },
  content: {
    width: '100%',
    padding: '25px',
  },
  drawers: {
    padding: '25px',
    paddingTop: '10px'
  },
  depotItem: {
    paddingBottom: '15px',
    paddingTop: '15px',
    borderBottom: `2px solid ${process.env.REACT_APP_COLOR_BACKGROUND_FILL}`,
  },
  moreInfo: {
    width: '100%',
    padding: '25px',
    paddingTop: '5px'
  }
}));

const Preview = ({
  intl,
  data,
  wasteType,
}) => {
  const classes = useStyles();

  return (
    <Dialog
      hasButtons={false}
      disableBackdropClick={false}
      button={(
        <CustomButton
          className={classes.dropdownItem}
          onClick={() => { }}
          label={(
            <Typography
              variant="bodySemiBold"
            >
              {intl.formatMessage({ id: 'CATEGORY.MENU.PREVIEW' })}
            </Typography>
          )}
        />
      )}
      title={(
        <Typography variant="h1">
          {intl.formatMessage({ id: 'GENERAL.PREVIEW' })}
        </Typography>
      )}
      contentClassName="p-0"
      content={(
        <div className={classes.container}>
          <div className={classes.header}>
            <Typography
              color={process.env.REACT_APP_COLOR_SECONDARY}
              variant="h1"
            >
              <div
                dangerouslySetInnerHTML={{
                  __html: data.mats.length ? data.mats[0].mat_nm : ''
                }}
              />
            </Typography>
          </div>
          <div className={classes.content}>
            <Typography
              variant="h1"
            >
              <div
                dangerouslySetInnerHTML={{
                  __html: wasteType.waste_type_admin_nm
                }}
              />

            </Typography>
            {!!data.mats.length
             && data.mats[0].mats_images !== null
             && !!data.mats[0].mats_images.length
             && (
             <div
               style={{
                 width: '100%',
                 height: '250px',
                 marginTop: '10px',
                 backgroundImage: `url(https://cdn.recyclecoach.com/gallery/${data.mats[0].mats_images[0].image_path.length ? encodeURI(data.mats[0].mats_images[0].image_path) : null})`,
                 backgroundSize: 'contain',
                 backgroundRepeat: 'no-repeat',
                 backgroundPosition: 'center'
               }}
             />
             )}
            {
              data.disposal_header && (
                <Typography
                  variant="bodySemiBold"
                  className="mt-2"
                  color={process.env.REACT_APP_COLOR_TEXT}
                >
                  <div
                    dangerouslySetInnerHTML={{
                      __html: data.disposal_header
                    }}
                  />
                </Typography>
              )
            }
            <Typography
              variant="body"
              className="mt-2"
              color={process.env.REACT_APP_COLOR_TEXT}
            >
              <div
                dangerouslySetInnerHTML={{
                  __html: data.custom_body ? data.custom_body : data.custom_description
                }}
              />
            </Typography>
          </div>
          {
            data.drawers !== null
            && !!data.drawers.length
            && data.drawers
              .map((item) => item && (
                <div
                  key={`${item.drawer_title} - ${Math.random()}`}
                  className={`w-100 d-flex justify-content-between align-items-center ${classes.drawers}`}
                >
                  <Typography variant="bodySemiBold">
                    <div
                      dangerouslySetInnerHTML={{
                        __html: item.drawer_title
                      }}
                    />
                  </Typography>
                  <ExpandIcon />
                </div>
              ))
          }
          {
            data.depots !== null && !!data.depots.length && (
            <div className={`w-100 d-flex flex-column ${classes.drawers}`}>
              <div className="d-flex justify-content-between align-items-center">
                <Typography variant="bodySemiBold">
                  {intl.formatMessage({ id: 'CATEGORY.CATEGORIES.DEPOT.TITLE' })}
                </Typography>
                <ExpandIcon />
              </div>
              {
                data.depots.map((item) => (
                  <div
                    key={`${data.drop_depot_id} - ${Math.random()}`}
                    className={`w-100 d-flex justify-content-between align-items-center ${classes.depotItem}`}
                  >
                    <Typography variant="body">
                      {item.drop_depot_admin_nm}
                    </Typography>
                  </div>
                ))
              }
            </div>
            )
          }
          {
            data.events !== null && !!data.events.length && (
            <div className={`w-100 d-flex flex-column ${classes.drawers}`}>
              <div className="d-flex justify-content-between align-items-center">
                <Typography variant="bodySemiBold">
                  {intl.formatMessage({ id: 'CATEGORY.CATEGORIES.DROP_OFF_EVENTS' })}
                </Typography>
                <ExpandIcon />
              </div>
              {
                data.events.map((item) => (
                  <div
                    key={`${data.drop_event_id} - ${Math.random()}`}
                    className={`w-100 d-flex justify-content-between align-items-center ${classes.depotItem}`}
                  >
                    <Typography variant="body">
                      {item.event_nm}
                    </Typography>
                  </div>
                ))
              }
            </div>
            )
          }
          {
            data.more_info && (
              <div
                className={`${classes.moreInfo}`}
              >
                <Typography
                  variant="bodySemiBold"
                >
                  {intl.formatMessage({ id: 'CATEGORY.CATEGORIES.CATEGORY_DESCRIPTION.MORE_INFORMATION' })}
                </Typography>

                <Typography
                  className="mt-2"
                  variant="body"
                  color={process.env.REACT_APP_COLOR_TEXT}
                >
                  <div
                    dangerouslySetInnerHTML={{
                      __html: data.more_info
                    }}
                  />
                </Typography>
              </div>
            )
          }
        </div>
      )}
    />
  );
};

const mapStateToProps = (store) => ({
  wasteType: store.category.wasteType,
});

export default injectIntl(connect(mapStateToProps, actions)(Preview));
