/* eslint-disable no-nested-ternary */
import withMock from './mock';
import axiosInstance from './axiosInstance';
import { getCookie } from '../utils';
import * as actions from '../pages/Auth/Logout/redux/actions';
import store from '../redux/store';
import { getClientLocation } from '../utils';

/**
 * Call To Server Method
 * A wrapper to all of application requests
 *
 * @param {string} method The request method
 * @param {string} endpoint The endpoint of the request
 * @param {Object} payload If you want to post some data
 * @param {Object} userDefinedOptions User Defined option for each API call
 */
const cts = async (method = 'get', endpoint, payload = {}, userDefinedOptions = {}, isBaseUrl = true, fullResponse = false) => {
  try {
    // Adding header authorization to user defined APIOptions
    const options = { ...userDefinedOptions };
    options.headers = options.headers || {};
    if (endpoint === 'users/login') {
      options.headers.Authorization = `Basic ${getCookie('_rc_cookie') ? getCookie('_rc_cookie') : ''}`;
    } else {
      options.headers.Authorization = `Bearer ${getCookie('_rc_access') ? getCookie('_rc_access') : ''}`;
    }

    const userLocation = getClientLocation();

    const baseUrl = userLocation === 'US' ? process.env.REACT_APP_API_BASE_URL_US : process.env.REACT_APP_API_BASE_URL;

    const {
      data,
      error,
      detail
    } = await withMock(axiosInstance, method)(
      `${isBaseUrl ? baseUrl : process.env.REACT_APP_API_GOOGLE_LOCATION_BASE_URL}/${endpoint}`,
      isBaseUrl ? method !== 'get' ? payload : options : null,
      isBaseUrl ? options : null
    );

    if (detail) {
      const err = new Error(detail.data.message);
      err.detail = detail;
      throw err;
    } else if (error || !data) {
      const err = new Error(error.message);
      err.detail = error;
      throw err;
    }

    return { data, error: null };
  } catch (error) {
    if (
      error.detail
      && (error.detail.status === 401 || (endpoint.includes('reports') && error.detail.status === 403))
    ) {
      store.dispatch(actions.logoutReceive());
    }

    // eslint-disable-next-line no-console
    console.error(`${error.message} \nEndpoint: ${endpoint}\nPayload: ${JSON.stringify(payload)}`);
    return { data: fullResponse ? error.detail.data : null, error };
  }
};

export default cts;
